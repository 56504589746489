const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const requiredFieldMessage = "";

class ValidationService {

    constructor() {
    }

    nif(val) {
        if (!val || val.length > 9)
            return "NIF inválido";

        if (!['1', '2', '3', '5', '6', '8'].includes(val.substr(0, 1)) &&
            !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(val.substr(0, 2)))
            return false;

        let total = val[0] * 9 + val[1] * 8 + val[2] * 7 + val[3] * 6 + val[4] * 5 + val[5] * 4 + val[6] * 3 + val[7] * 2;

        let modulo11 = total - parseInt(total / 11) * 11;
        let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

        return val[8] == comparador || "NIF inválido";
    }

    email(val) {
        if (!val || val.length === 0)
            return true;

        return emailRegex.test(val.toLowerCase()) || "E-mail inválido";
    }

    empty(val) {
        if (!val)
            return requiredFieldMessage;

        return val.length > 0 || requiredFieldMessage;
    }

    phone(val) {
        if (!val)
            return true;

        if (val.length > 9)
            return "Formato inválido";

        return val.length > 0 || requiredFieldMessage;
    }

    requiredFile(val) {
        if (!val)
            return requiredFieldMessage;
        return true;
    }

    validate(refs) {
        var hasErrors = false;

        Object.keys(refs).forEach(f => {
            if (refs[f].validate && !refs[f].validate(true))
                hasErrors = true;
        })

        return !hasErrors;
    }

    clear(refs) {
        Object.keys(refs).forEach(f => {
            if (refs[f].reset)
                refs[f].reset();
        })
    }
}

export default new ValidationService();