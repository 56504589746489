<template>
  <div>
    <v-icon small color="green darken-2">
      mdi-checkbox-marked-circle-outline
    </v-icon>
    {{text}}
  </div>
</template>

<style lang="scss">
</style>

<script>
export default {
  name: "ServiceListItem",
  props: ["text"],
};
</script>
