<template>
  <div>
    <v-app-bar id="top-menu" app height="90px" dense flat elevate-on-scroll>
      <v-container>
        <a v-on:click="$router.push('/')">
          <v-img
            :src="require('../../assets/logo-sm.png')"
            v-if="!this.$vuetify.breakpoint.mobile"
            contain
          ></v-img>
          <v-img
            :src="require('../../assets/logo-md.png')"
            v-if="this.$vuetify.breakpoint.mobile"
            height="40px"
            width="40px"
            contain
          ></v-img>
        </a>
        <label> Área do Cliente</label>
        <v-spacer></v-spacer>
        <v-menu bottom left v-if="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-bell</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>Alterar Senha</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list></v-menu
        >
        <UserCard />
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss">
#top-menu {
  z-index: 10;
}

#top-menu {
  a {
    margin-top: 6px;
    .v-image {
      display: inline-block;
      max-width: 120px;
      max-height: auto;
    }
  }

  label {
    display: inline-block;
    min-width: 200px;
    margin-top: 12px;
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  .v-tab {
    text-transform: none;
  }

  .v-tab--active {
    color: #026537;
  }

  .container {
    display: flex;
  }

  .v-tabs-slider.green {
    background-color: #9ccc62 !important;
    border-color: #9ccc62 !important;
  }
}

#top-menu.theme--light {
  background-color: white;
}
</style>

<script>
import UserCard from "../UserCard";

export default {
  name: "Menu",
  components: { UserCard },
  methods: {
    showExtension(component) {
      this.currentExtension = component;
      this.extension = true;
    },
  },
};
</script>
