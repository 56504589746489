import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import form from './modules/form'
import formEditor from './modules/formEditor'
import workspace from './modules/workspace'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    form,
    formEditor,
    workspace
  },
  strict: process.env.NODE_ENV !== 'production',
})