<template>
  <v-app id="app">
    <v-main>
      <router-view />
      <v-snackbar v-model="show" :color="color" top centered>
        {{ message }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url(fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(fonts/Raleway/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

#app {
  font-family: "Raleway", "Roboto", "OpenSans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

.container {
  max-width: 1170px;
}

@media (min-width: 960px) {
  .container {
    max-width: 900px !important;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1170px !important;
  }
}

@media (min-width: 1904px) {
  .container {
    max-width: 1170px !important;
  }
}

#app.theme--light {
  color: #2c3e50;
}
.v-application {
  .v-btn.primary {
    background: #026537 !important;
    background-color: #026537;
    text-transform: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", "Roboto", "OpenSans";
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: bold;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
}

h2 {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}

h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

h2.subtitle,
h3.subtitle {
  font-weight: bold;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.fade-in-2s {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn ease 0.5s;
  -webkit-animation: slideIn ease 0.5s;
  -moz-animation: slideIn ease 0.5s;
  -o-animation: slideIn ease 0.5s;
  -ms-animation: slideIn ease 0.5s;
}

@keyframes slideIn {
  0% {
    margin-left: -5000px;
    opacity: 0.5;
    width: initial;
  }
  100% {
    opacity: 1;
    margin-left: initial;
  }
}
</style>

<script>
// @ is an alias to /src
export default {
  name: "App",
  created() {
    this.$root.$on("notify", item => {
      this.show = true;
      this.message = item.message;
      this.color = item.error ? "error" : "success";
      window.setTimeout(5000, () => this.show = false);
    });
  },
  data() {
    return {
      message: "",
      color: "success",
      show: false
    };
  },
};
</script>

