<template>
  <div>
    <section id="contacto">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center">
              <h2 class="subtitle">Contacto</h2>
              <p>
                Entre em contacto com nossa equipa através do formulário abaixo
                e lhe retornaremos o mais breve possível.
              </p>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <v-card flat>
                  <v-card-text>
                    <v-text-field
                      label="Nome"
                      :rules="[validator.empty]"
                      v-model="message.name"
                      ref="name"
                    ></v-text-field>
                    <v-text-field
                      label="E-mail"
                      v-model="message.email"
                      :rules="[validator.empty, validator.email]"
                      ref="email"
                    ></v-text-field>
                    <v-text-field
                      label="Telefone"
                      v-model="message.phone"
                      :rules="[validator.phone]"
                      ref="phone"
                    ></v-text-field>
                    <v-text-field
                      label="Assunto"
                      :rules="[validator.empty]"
                      v-model="message.subject"
                      ref="subject"
                    ></v-text-field>
                    <v-textarea
                      outlined
                      label="Mensagem"
                      ref="message"
                      v-model="message.message"
                      :rules="[validator.empty]"
                    ></v-textarea>
                    <v-btn color="primary" tile v-on:click="sendMessage()">
                      Enviar
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card flat>
                  <v-card-text>
                    <v-img src="/img/map.png" max-height="300" contain></v-img>
                    <div class="address">
                      <h4>Morada</h4>
                      <h5>Rua Nau Trindade, 103 - 4000-355 - Porto</h5>
                      <h4>Telefone</h4>
                      <h5>22 519 4710</h5>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style lang="scss">
#contacto {
  background-color: white;

  .v-card {
    height: 100%;
    padding: 20px;

    .v-image {
      border: 1px solid #e0e0e0;
    }
  }

  .address {
    margin-top: 20px;

    h4 {
      font-size: 1.2rem;
      color: #026537;
      margin-bottom: 10px;
    }

    h5 {
      font-size: 1rem;
    }
  }
}
</style>

<script>
import validationService from "../../services/validationService";
import httpService from "../../services/httpService";
import NotificationService from "../../services/notificationService";

export default {
  name: "Contact",
  methods: {
    sendMessage() {
      if (!this.validator.validate(this.$refs)) return;

      httpService
        .post("/api/contacts", this.message)
        .then(() => {
          this.message = {};
          this.notify.success("Mensagem enviada, em breve retornaremos seu contacto.");
          this.validator.clear(this.$refs);
        })
        .catch(() => {
          this.notify.error("Não foi possível enviar a mensage, por favor tente novamente.");
        });
    },
  },
  data() {
    return {
      message: {},
      notify: new NotificationService(this),
      validator: validationService,
    };
  },
};
</script>
