<template>
  <div>
    <section id="servicos" class="servicos">
      <Banner image="/img/services.jpg" height="350">
        <v-row>
          <v-col cols="12" class="principio-item missao">
            <h2 class="subtitle text-center">Serviços</h2>
            <p>
              A Guerra & Ferreirinhas é uma empresa vocacionada para serviços
              que actuam nas áreas da contabilidade, gestão e recursos humanos.
              Cobrindo uma vasta área de actividades específicas, a Guerra &
              Ferreirinhas tem já a sua posição no mercado e pretende ser um
              referencial de competência para todo o sector:
            </p>
          </v-col>
        </v-row>
      </Banner>
    </section>
    <section id="contabilidade" class="contabilidade">
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <h3 class="subtitle text-center">Contabilidade</h3>
            <p class="text-left">
              Dispomos de todos os meios físicos e materiais para lhe prestar um
              serviço de elevada qualidade. Cada cliente é único e, por isso,
              adaptamo-nos às suas diferentes necessidades, sempre prestando o
              serviço segundo o seu enquadramento e dimensão assim distinguimos
              entre Pequenas e Médias Empresas e Empresários. Para cada caso
              específico oferecemos os seguintes serviços:
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <h4 class="text-center">Pequenas e médias empresas</h4>
            <div class="text-left">
              <ServiceListItem text="Processamento de Contabilidade Geral" />
              <ServiceListItem
                text="Processamento de Contabilidade Analítica"
              />
              <ServiceListItem text="Recuperação de Contabilidades" />
              <ServiceListItem text="Informação periódica de apoio à Gestão" />
              <ServiceListItem text="Balancetes Mensais" />
              <ServiceListItem text="Balanços Trimestrais" />
              <ServiceListItem
                text="Demonstração de Resultados Mensais e Trimestrais"
              />
              <ServiceListItem
                text="Preenchimento e Envio de Declarações Fiscais e de outra natureza"
              />
              <ServiceListItem text="Intrastat" />
              <ServiceListItem
                text="Assim como outras obrigações fiscais necessárias"
              />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <h4 class="text-center">Empresários</h4>
            <div class="text-left">
              <ServiceListItem text="Processamento de contabilidade" />
              <ServiceListItem text="Informação periódica de apoio à Gestão" />
              <ServiceListItem text="Livros Informatizados" />
              <ServiceListItem
                text="Preenchimento e Envio de Declarações Fiscais e de outra natureza"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="recursos-humanos">
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <h3 class="subtitle text-center">Recursos Humanos</h3>
            <p class="text-center">
              Tendo à nossa disposição todos os meios e os conhecimentos
              necessários estamos aptos para prestar os serviços de:
            </p>
            <ServiceListItem
              v-for="item in recursosHumanos"
              :text="item"
              :key="item"
            />
          </v-col> </v-row
      ></v-container>
    </section>
    <section id="gestao" class="gestao-empresarial">
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <h3 class="subtitle text-center">Gestão Empresarial</h3>
            <p class="text-left">
              Oferecemos um conjunto de serviços ao nível da Gestão de Empresas
              que nos permite ajudá-los no desenvolvimento dos seus negócios.
              Aconselhamos os nossos clientes periodicamente, através de
              relatórios de gestão que abordam áreas desde a conta de exploração
              mensal, às margens brutas, aos custos e resultados, à análise dos
              desvios, bem como a análise dos fluxos de caixa, das despesas e a
              reconciliação de contas bancárias. Procuramos a rentabilização dos
              nossos clientes, prestando aconselhamento e concebendo soluções
              específicas para cada um deles, estando aptos para oferecer os
              seguintes serviços:
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <h4 class="text-center">Gestão de bancos</h4>
            <div class="text-left">
              <ServiceListItem
                v-for="item in gestaoBancos"
                :text="item"
                :key="item"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <h4 class="text-center">Gestão Económica e Financeira</h4>
            <div class="text-left">
              <ServiceListItem
                v-for="item in gestaoEconomicaFinanceira"
                :text="item"
                :key="item"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <h4 class="text-center">Gestão de Imobilizado</h4>
            <div class="text-left">
              <ServiceListItem
                v-for="item in gestaoImobilizado"
                :text="item"
                :key="item"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="auditoria">
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <h3 class="subtitle text-center">Auditoria</h3>
            <p class="text-left">
              A contabilidade é um importante instrumento de gestão, sendo para
              nós fundamental a realização de reuniões periódicas, com cada um
              dos nossos clientes, de forma a analisarmos, em conjunto, os
              resultados obtidos. Efectuamos o acompanhamento fiscal dos nossos
              clientes procurando obter os melhores resultados fiscais para os
              nossos clientes, dentro dos preceitos legais. Processamos e
              enviamos todas as obrigações fiscais.
            </p>
          </v-col>
        </v-row></v-container
      >
    </section>
  </div>
</template>

<style lang="scss">
.servicos {
  background-color: darken(#026537, 10%);
  padding: 0;

  .subtitle {
    color: white;
  }

  p {
    color: white;
  }
}

#auditoria,
#recursos-humanos {
  background-color: white;
}
</style>

<script>
import ServiceListItem from "./ServiceListItem";
import Banner from "../Banner";

export default {
  name: "Services",
  components: { ServiceListItem, Banner },
  data() {
    return {
      gestaoBancos: [
        "Gestão de contas bancárias",
        "Reconciliação bancária",
        "Dossier bancário",
        "Gestão de créditos",
      ],
      gestaoEconomicaFinanceira: [
        "Gestão de Recebimentos de Clientes",
        "Gestão de Pagamentos a Fornecedores",
        "Orçamentação e Planeamento da Tesouraria",
        "Avaliação do Risco",
        "Estudos de Viabilidade de Empresas",
        "Relatórios de Evolução Financeira",
        "Relatórios de Gestão",
      ],
      gestaoImobilizado: [
        "Registo de aquisições",
        "Cálculo de amortizações e reavaliações",
        "Registo de reparações",
        "Alienações e abate",
        "Gestão do cadastro de bens de imobilizado",
      ],
      recursosHumanos: ["Processamento de salários", "Segurança Social"],
    };
  },
};
</script>
