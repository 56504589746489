<template>
  <div id="admin-login">
    <v-container>
      <br />
      <br />
      <br />
      <v-row justify="center" no-gutters>
        <v-col cols="12" sm="6" lg="8" xl="6" align="center">
          <v-card
            id="customer-login"
            class="customer-login"
            flat
            max-width="400px"
          >
            <v-card-text class="text-center">
              <v-row no-gutters class="logo">
                <v-col cols="12" class="text-center">
                  <v-img
                    :src="require('../../assets/logo-sm.png')"
                    contain
                    height="50px"
                  ></v-img>
                </v-col>
              </v-row>
              <br /><br />
              <h3 class="subtitle">Login</h3>
              <v-text-field
                label="E-mail"
                v-model="userName"
                :rules="[validator.email, validator.empty]"
                ref="email"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                label="Palavra passe"
                v-model="password"
                :rules="[validator.empty]"
                type="password"
                ref="password"
                outlined
                dense
              ></v-text-field>
              <v-btn v-on:click="login()" color="primary" tile> Entrar </v-btn>
              <br />
              <PasswordRecovery type="email" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
</style>
<script>
import authService from "../../services/authService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";
import PasswordRecovery from "../../components/landing/PasswordRecovery";

export default {
  name: "Login",
  components: { PasswordRecovery },
  methods: {
    login() {
      if (!this.validator.validate(this.$refs)) return;
      authService
        .login(this.userName, this.password, "admin")
        .then(() => {
          this.$router.push("/admin/customers");
        })
        .catch(err => {
          if (!err.response.data) {
            this.notify.error("Dados de acesso inválidos");
            return;
          }

          if (err.response.data.message)
            this.notify.error(err.response.data.message);
          else if (err.response.data.Message)
            this.notify.error(err.response.data.Message);
        });
    },
  },
  data() {
    return {
      validator: validationService,
      userName: "",
      password: "",
      notify: new NotificationService(this),
    };
  },
};
</script>