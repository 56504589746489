<template>
  <v-dialog v-model="dialog" max-width="400px" @click:outside="clearForm()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="primary" v-bind="attrs" v-on="on">
        Adicionar ficheiro
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Novo ficheiro</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                ref="file"
                label="Ficheiro"
                :rules="[validator.requiredFile]"
                prepend-icon=""
                v-on:change="selectFile"
                append-icon="mdi-paperclip"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="folders"
                ref="folder"
                label="Pasta"
                :rules="[validator.empty]"
                item-text="name"
                item-value="id"
                v-model="document.folderId"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="saveFile()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 
<script>
import httpService from "../../services/httpService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";

export default {
  name: "CustomerFileForm",
  props: ["customerid", "folders"],
  methods: {
    clearForm() {
      this.validator.clear(this.$refs);
    },
    selectFile(file) {
      this.document.file = file;
      this.document.name = file.name;
      this.$forceUpdate();
    },
    saveFile() {
      if (!this.validator.validate(this.$refs)) return;
      this.document.customerId = this.customerid;

      httpService
        .postFormData(
          "/api/customers/" + this.document.customerId + "/documents",
          this.document
        )
        .then(() => {
          this.dialog = false;
          this.$root.$emit("customer-files-changed", {
            folder: this.document.folderId,
            customer: this.customerid,
          });
          this.document = {};
          this.notify.success("Ficheiro adicionado com sucesso.");
          this.clearForm();
        });
    },
  },
  data() {
    return {
      validator: validationService,
      notify: new NotificationService(this),
      dialog: false,
      document: {},
    };
  },
};
</script>