<template>
  <div>
    <v-app-bar id="top-menu" app height="90px" elevate-on-scroll>
      <v-container>
        <v-img :src="require('../../assets/logo-sm.png')" contain></v-img>

        <v-spacer></v-spacer>
        <ul class="menu" v-if="!this.$vuetify.breakpoint.mobile">
          <li
            v-for="tab in tabs"
            :key="tab.id"
            :class="{ active: selectedTab === tab }"
            v-on:click="changeTab(tab)"
          >
            {{ tab.name }}
          </li>
        </ul>

        <v-btn
          icon
          v-bind="attrs"
          v-on:click="sheet = true"
          v-if="this.$vuetify.breakpoint.mobile"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-bottom-sheet v-model="sheet">
          <v-list>
            <v-list-item
              v-for="tile in tabs"
              :key="tile.name"
              @click="
                sheet = false;
                changeTab(tile);
              "
            >
              <v-list-item-title>{{ tile.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push('/admin')">
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-bottom-sheet>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss">
#top-menu {
  z-index: 10;
}

#top-menu {
  .menu {
    margin-top: 8px;

    li {
      cursor: pointer;
      display: inline;
      margin-left: 20px;
      padding-bottom: 8px;
      border-bottom: 3px solid transparent;
    }

    li.active {
      border-bottom-color: #9fc35a !important;
      transition: border-bottom-color 0.2s;
    }
  }

  .v-image {
    display: inline-block;
    max-width: 180px;
    max-height: 60px;
  }

  .v-tab {
    text-transform: none;
  }

  .v-tab--active {
    color: #026537;
  }

  .container {
    display: flex;
  }

  .v-tabs-slider.green {
    background-color: #9ccc62 !important;
    border-color: #9ccc62 !important;
  }
}

#top-menu.theme--light {
  background-color: white;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "Menu",
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
      document.getElementById(tab.href).scrollIntoView();
    },
    showExtension(component) {
      this.currentExtension = component;
      this.extension = true;
    },
    listenScroll() {
      document.addEventListener("scroll", () => {
        if (this.ticking) return;

        window.requestAnimationFrame(() => {
          var selTab = null;
          for (var key in this.tabs) {
            var tab = this.tabs[key];
            var el = document.getElementById(tab.href);
            if (window.scrollY >= el.offsetTop) selTab = tab;
          }
          this.selectedTab = selTab;
          this.ticking = false;
        });
        this.ticking = true;
      });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.loggedUser,
    }),
  },
  created() {
    this.$store.dispatch("auth/getUser");
    this.selectedTab = this.tabs[0];
    this.listenScroll();
  },
  data() {
    return {
      ticking: false,
      sheet: false,
      selectedTab: 0,
      tabs: [
        { id: 0, name: "Home", href: "app" },
        { id: 1, name: "Empresa", href: "empresa" },
        { id: 2, name: "Serviços", href: "servicos" },
        { id: 3, name: "Nossa Equipa", href: "team" },
        { id: 4, name: "Contacto", href: "contacto" },
      ],
    };
  },
};
</script>
