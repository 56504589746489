<template>
  <v-card flat class="card-news">
    <h3 class="subtitle">Notícias</h3>
    <div v-for="item in news" :key="item.id" class="news-item">
      <a class="title" :href="item.link" target="_blank">{{ item.title }}</a>
      <label class="details"
        >{{ formatDate(item.date) }} - {{ item.source }}</label
      >
    </div>
    <v-card-actions>
      <v-row>
        <v-col class="text-center">
          <v-btn class="mx-2" fab small :disabled="page === 0" v-on:click="prevPage()">
            <v-icon dark> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn class="mx-2" fab small v-on:click="nextPage()">
            <v-icon dark> mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
#main {
  strong {
    font-weight: bolder;
    color: #555;
  }

  .v-card.card-news {
    background-color: #f2f2f2;
    padding: 10px 25px;
    min-height: 50vh;

    .news-item {
      margin-bottom: 10px;

      label {
        display: block;
      }
      a.title {
        color: #777;
        text-decoration: underline;
        font-size: 0.8rem !important;
      }
      label.details {
        font-size: 0.8rem !important;
        color: #aaa;
      }
    }
  }
}
</style>

<script>
import httpService from "../../services/httpService";
import moment from "moment";

export default {
  name: "Main",
  methods: {
    load() {
      httpService.get("/api/news?size=5&skip=" + this.page * 5).then((resp) => {
        this.news = resp.data;
        if (this.news.length === 0)
          this.prevPage()
      });
    },
    prevPage() {
      this.page--;
      if (this.page < 0)
        this.page = 0;
      this.load();
    },
    nextPage() {
      this.page++;
      this.load();
    },
    formatDate(val) {
      return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      page: 0,
      news: [],
    };
  },
};
</script>
