<template>
  <v-row>
    <v-col cols="6" sm="4" md="2" v-for="item in files" :key="item.name">
      <File :item="item" :editing="editing"/>
    </v-col>
  </v-row>
</template>
 
<script>
import File from "@/components/customers/File.vue";

import httpService from "../services/httpService";

export default {
  name: "CustomerFileRender",
  props: ["customer", "folder", "editing"],
  components: { File },
  methods: {
    loadFiles() {
      httpService
        .get(
          "/api/customers/" + this.customer + "/documents?folder=" + this.folder
        )
        .then((resp) => {
          this.files = resp.data;
        });
    }
  },
  created() {
    this.loadFiles();
    this.$root.$on("customer-files-changed", (evt) => {
      if (this.folder === evt.folder) this.loadFiles();
    });
  },
  data() {
    return {
      files: [],
    };
  },
};
</script>