<template>
  <div>
    <Banner id="page-header" image="/img/back.jpg" height="500">
      <v-row>
        <v-col cols="12" md="8" class="header-left">
          <v-row>
            <v-col cols="auto">
              <v-img
                :src="require('../../assets/logo-md.png')"
                height="110"
                width="110"
                cover
              ></v-img>
            </v-col>
            <v-col>
              <div class="header-text fade-in-2s">
                <h1>Guerra & Ferreirinhas</h1>
                <h3>Ao seu dispor desde 1989</h3>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" v-if="!this.$vuetify.breakpoint.mobile">
          <Login></Login>
        </v-col>
      </v-row>
    </Banner>
    <section id="login-section" v-if="this.$vuetify.breakpoint.mobile">
      <v-container>
        <Login></Login>
      </v-container>
    </section>
    <section id="logos-section">
      <v-container>
        <v-row>
          <v-col>
            <v-dialog v-model="projectDetailsOpened" width="600">
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on">
                  <img width="200" src="/Logo_Portugal_2020_Cores.png" />
                </a>
                <a v-bind="attrs" v-on="on">
                  <img width="200" src="/principal_highres.jpg" />
                </a>
                <a v-bind="attrs" v-on="on">
                  <img width="200" src="/insignia_fedr-01.jpg" />
                </a>
              </template>

              <v-card>
                <v-card-title class="headline">
                  <span>Projeto</span><v-spacer></v-spacer>
                  <v-btn icon v-on:click="projectDetailsOpened = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <ul class="project-data">
                    <li>
                      <strong> Designação do projeto:</strong> Adaptação da
                      atividade de Guerra e Ferreirinhas ao contexto COVID-19
                    </li>
                    <li>
                      <strong> Código do projeto:</strong>
                      NORTE-02-08B9-FEDER-063790
                    </li>
                    <li>
                      <strong> Objetivo principal:</strong> Reforçar a
                      competitividade das PME
                    </li>
                    <li><strong> Região de intervenção:</strong> Norte</li>
                    <li>
                      <strong> Entidade beneficiária:</strong> Guerra e
                      Ferreirinhas, Lda
                    </li>
                    <li><strong> Data de aprovação:</strong> 06-07-2020</li>
                    <li><strong> Data de início:</strong> 01-06-2020</li>
                    <li><strong> Data de conclusão:</strong> 23-03-2021</li>
                    <li>
                      <strong> Custo total elegível:</strong> 5.300,00 EUR
                    </li>
                    <li>
                      <strong> Apoio financeiro da União Europeia:</strong>
                      FEDER – 2.650,00 EUR
                    </li>
                    <li>
                      Este projecto visa contribuir para uma adequada resposta
                      às recomendações das autoridades competentes à adaptação
                      do atual contexto pandémico.
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<style lang="scss">
#page-header {
  position: relative;

  .header-text {
    color: white;
    text-transform: uppercase;
    line-height: 45px;
  }

  .header-left {
    margin-top: 50px;
  }

  .v-overlay__content {
    width: 100% !important;
  }
}

#logos-section {
  background-color: white;

  .container {
    text-align: center;

    a {
      display: inline-block;
      top: 0;
      margin-top: 0;
      height: 80px;
      padding: 10px;
    }
  }
}
ul.project-data {
  padding: 0;
  li {
    list-style: none;
  }
}
</style>

<script>
import Banner from "../Banner";
import Login from "./Login";

export default {
  name: "Header",
  components: { Banner, Login },
  data() {
    return {
      projectDetailsOpened: false,
    };
  },
};
</script>
