<template>
  <div id="customer">
    <Menu :customer="customer" />
    <v-container>
      <v-row>
        <v-col cols="12" md="9">
          <h3 class="text-left">{{customer.name}}</h3>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            rounded
            single-line
            dense
            v-model="password"
            append-icon="mdi-magnify"
            type="text"
            name="input-10-1"
            label="buscar..."
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="customer">
                    <CustomerFolderRender :customer="customer.id" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
</style>
<script>
import Menu from "@/components/customers/Menu.vue";
import CustomerFolderRender from "@/components/CustomerFolderRender.vue";
import authService from "../services/authService";

export default {
  name: "Customer",
  components: { Menu, CustomerFolderRender },
  created() {
    authService.getUser().then(user => {
      this.customer = user;
    })
  },
  methods: {
  },
  data() {
    return {
      customer: null
    };
  },
};
</script>