import axios from "axios"

class HttpService {

    getHeaders() {
        var headers = {};

        var user = window.sessionStorage.getItem("user");

        if (user !== null) {
            var userData = JSON.parse(user);
            headers.Authorization = "Bearer " + userData.token;
        }

        return headers;
    }

    get(url) {
        return new Promise((resolve, reject) => {
            url += (url.indexOf('?') > -1 ? "&" : "?") + "_r=" + Math.random();

            axios.get(url, {
                headers: this.getHeaders()
            }).then(resolve).catch(reject);
        });
    }

    postFormData(url, data) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();

            for (var key in data)
                formData.append(key, data[key]);

            axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...this.getHeaders()
                }
            }).then(resolve).catch(reject);
        });
    }

    post(url, data) {
        return new Promise((resolve, reject) => {
            axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                    ...this.getHeaders()
                }
            }).then(resolve).catch(reject);
        });
    }


    put(url, data) {
        return new Promise((resolve, reject) => {
            axios.put(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                    ...this.getHeaders()
                }
            }).then(resolve).catch(reject);
        });
    }


    delete(url) {
        return new Promise((resolve, reject) => {
            axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    ...this.getHeaders()
                }
            }).then(resolve).catch(reject);
        });
    }
}

export default new HttpService();