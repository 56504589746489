<template>
  <div>
    <Menu />
    <Header />
    <Main />
    <Mission />
    <Services />
    <Team />
    <Contact />
    <v-footer class="footer">
        <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }} — <strong>Guerra & Ferreirinhas</strong>
        </v-col>
        <CookieLaw />
    </v-footer>
  </div>
</template>

<style lang="scss">
section {
  padding: 40px;
  width: 100%;
  display: block;
}

.footer {
  background-color: darken(#026537, 10%) !important;
  color: white !important;
}
</style>
<script>
import Menu from "@/components/landing/Menu.vue";
import Header from "@/components/landing/Header.vue";
import Main from "@/components/landing/Main.vue";
import Mission from "@/components/landing/Mission.vue";
import Services from "@/components/landing/Services.vue";
import Team from "@/components/landing/Team.vue";
import Contact from "@/components/landing/Contact.vue";
import CookieLaw from "@/components/CookieLaw.vue";

export default {
  name: "Home",
  components: {
    Menu,
    Header,
    Main,
    Mission,
    Services,
    Team,
    Contact,
    CookieLaw,
  },
};
</script>