<template>
  <div id="banner">
    <v-parallax :min-height="height" :src="image" cover></v-parallax>
    <v-overlay absolute opacity="0.8" :dark="false">
      <v-container>
        <slot></slot>
      </v-container>
    </v-overlay>
  </div>
</template>

<style lang="scss">
#banner {
  position: relative;

  .v-overlay__content {
    width: 100% !important;
  }
}
</style>

<script>
export default {
  name: "Banner",
  props: ["image", "height"],
};
</script>
