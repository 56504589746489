<template>
  <div id="customer">
    <Menu />
    <v-container>
      <v-row>
        <v-col cols="12" md="9">
          <h3 class="text-left">Clientes</h3>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            rounded
            single-line
            dense
            append-icon="mdi-magnify"
            type="text"
            name="input-10-1"
            label="buscar..."
            v-model="text"
            v-on:keyup.enter="load()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-dialog v-model="deleteDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Deseja remover este cliente?
          </v-card-title>

          <v-card-text>
            Todos os dados relativos a este cliente será removido da base de
            dados.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="deleteDialog = false">
              Cancelar
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteCustomer()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="passwordDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Deseja redefinir a palavra chave deste cliente?
          </v-card-title>

          <v-card-text>
            Uma vez modificada o cliente precisará da nova para conseguir aceder
            ao sistema.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="passwordDialog = false">
              Cancelar
            </v-btn>

            <v-btn color="red darken-1" text @click="resetCustomerPassword()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" max-width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            color="primary"
            v-bind="attrs"
            v-on="on"
            v-on:click="clearForm()"
          >
            Novo cliente
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Novo cliente</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nome"
                    ref="name"
                    v-model="editingCustomer.name"
                    :rules="[validator.empty]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="NIF"
                    v-model="editingCustomer.nif"
                    ref="nif"
                    :rules="[validator.empty, validator.nif]"
                    counter="9"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="E-mail"
                    ref="email"
                    v-model="editingCustomer.email"
                    :rules="[validator.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Telefone"
                    ref="phone"
                    :rules="[validator.phone]"
                    v-model="editingCustomer.phone"
                    counter="9"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-if="editingCustomer.id"
                    v-model="editingCustomer.active"
                    inset
                    :label="editingCustomer.active ? 'Ativo' : 'Inativo'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="customers"
            item-key="name"
            ref="table"
            class="elevation-1"
          >
            <template v-slot:item.active="{ item }">
              <v-chip
                class="ma-2"
                :color="!item.active ? 'red' : 'green'"
                text-color="white"
              >
                {{ !item.active ? "Inativo" : "Ativo" }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" small>
                    <v-icon color="grey">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editItem(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="passwordRecovery(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-lock-reset</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>Redefinir senha</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.avatar="{ item, attrs, on }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-avatar size="28" color="gray">
                  <img :src="'/account/' + item.id + '/photo'" /> </v-avatar
              ></v-btn>
            </template>
            <template v-slot:item.name="{ item }">
              <a v-on:click="$router.push('/admin/customers/' + item.id)">{{
                item.name
              }}</a>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
</style>
<script>
import Menu from "@/components/admin/Menu.vue";
import httpService from "../../services/httpService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";

export default {
  name: "Home",
  components: { Menu },
  methods: {
    clearForm() {
      this.editingCustomer = {};
    },
    load() {
      httpService
        .get("/api/customers?size=1000&name=" + this.text)
        .then((resp) => {
          this.customers = resp.data;
        });
    },
    editItem(item) {
      this.editingCustomer = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.editingCustomer = {
        id: item.id,
      };
    },
    passwordRecovery(item) {
      this.passwordDialog = true;
      this.editingCustomer = {
        id: item.id,
      };
    },
    deleteCustomer() {
      httpService
        .delete("/api/customers/" + this.editingCustomer.id)
        .then(() => {
          this.load();
          this.editingCustomer = {};
          this.deleteDialog = false;
        });
    },
    resetCustomerPassword() {
      httpService
        .post("/account/" + this.editingCustomer.id + "/passwordReset")
        .then((res) => {
          this.editingCustomer = {};
          this.passwordDialog = false;
          this.notify.success(
            "A senha do cliente foi redefinida. Nova senha: " + res.data
          );
        });
    },
    add() {
      this.editingCustomer.active = true;

      return httpService.post("/api/customers", this.editingCustomer);
    },
    update() {
      return httpService.put(
        "/api/customers/" + this.editingCustomer.id,
        this.editingCustomer
      );
    },
    save() {
      if (!this.validator.validate(this.$refs)) return;

      var promise = this.editingCustomer.id ? this.update() : this.add();

      promise.then(() => {
        this.load();
        this.editingCustomer = {};
        this.dialog = false;
        this.notify.success("Os dados do cliente foram salvos com sucesso.");
      });
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      notify: new NotificationService(this),
      dialog: false,
      deleteDialog: false,
      passwordDialog: false,
      text: "",
      validator: validationService,
      editingCustomer: {},
      customers: [],
      headers: [
        { text: "", value: "actions", width: "30px" },
        { text: "", value: "avatar", width: "32px" },
        {
          text: "Nome",
          align: "start",
          value: "name",
        },
        { text: "NIF", value: "nif" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "phone" },
        { text: "Situação", value: "active", width: "60px" },
      ],
    };
  },
};
</script>