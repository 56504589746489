<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="folder in folders" :key="folder.id">
      <v-expansion-panel-header>
        <div
          @drop.prevent="dropFiles($event, folder.id)"
          @dragover.prevent
          v-cloak
        >
          <v-row>
            <v-col cols="auto">
              <v-icon>mdi-folder</v-icon>
              {{ folder.name }}
              <span v-if="editing">
                <v-dialog v-model="deleteDialog" max-width="400">
                  <v-card>
                    <v-card-title class="headline">
                      Deseja remover esta pasta?
                    </v-card-title>

                    <v-card-text>
                      Todos os ficheiros desta pasta serão removidos e não
                      poderão ser recuperados
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="grey darken-1"
                        text
                        @click="deleteDialog = false"
                      >
                        Cancelar
                      </v-btn>

                      <v-btn color="red darken-1" text @click="remove(folder)">
                        Confirmar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" small>
                      <v-icon color="grey">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-on:click="deleteDialog = true">
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>
          </v-row>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          @drop.prevent="dropFiles($event, folder.id)"
          @dragover.prevent
          v-cloak
          class="drop-container"
        >
          <CustomerFileRender
            :customer="customer"
            :folder="folder.id"
            :editing="editing"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style lang="scss">
.drop-container {
  min-height: 200px;
}
</style>

<script>
import CustomerFileRender from "@/components/CustomerFileRender.vue";
import NotificationService from "../services/notificationService";

import httpService from "../services/httpService";

export default {
  name: "CustomerFolderRender",
  props: ["customer", "editing"],
  components: { CustomerFileRender },
  methods: {
    loadFolders() {
      httpService
        .get("/api/customers/" + this.customer + "/folders")
        .then((resp) => {
          this.folders = resp.data;
        });
    },
    addFile(file, folder) {
      var document = {
        file: file,
        name: file.name,
        folderId: folder,
        customerId: this.customer,
      };

      console.log(document);

      httpService
        .postFormData(
          "/api/customers/" + this.customer + "/documents",
          document
        )
        .then(() => {
          this.dialog = false;
          this.$root.$emit("customer-files-changed", {
            folder: document.folderId,
            customer: this.customer,
          });
          this.notify.success("Ficheiro adicionado com sucesso.");
        });
    },
    dropFiles(e, folderId) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => this.addFile(f, folderId));
    },
    remove(folder) {
      httpService
        .delete("/api/customers/" + this.customer + "/folders/" + folder.id)
        .then(() => {
          this.deleteDialog = false;
          this.notify.success("Pasta removida com sucesso.");
          this.$root.$emit("folder-saved");
        });
    },
  },
  created() {
    this.loadFolders();
    this.$root.$on("folder-saved", () => this.loadFolders());
  },
  data() {
    return {
      deleteDialog: false,
      notify: new NotificationService(this),
      folders: [],
    };
  },
};
</script>