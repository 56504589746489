<template>
  <section id="empresa">
    <v-container id="main">
      <v-row>
        <v-col cols="12" md="8">
          <h2 class="subtitle text-center">A Empresa</h2>
          <p class="fade-in">
            Constituída em 1989, Guerra & Ferreirinhas desde início vocacionada
            para as áreas de
            <strong
              >contabilidade, consultadoria, gestão, auditoria e recursos
              humanos</strong
            >, tem como objectivo prestar o melhor serviço e,
            conscienciosamente, exceder as expectativas dos nossos clientes,
            através de formação contínua, integridade e trabalho árduo.
            <br /><br />Guerra & Ferreirinhas reúne um corpo técnico altamente
            especializado, constituído por uma equipa multidisciplinar, e
            apetrechada com equipamento de última geração, estando dimensionada
            para atender rapidamente às múltiplas solicitações dos seus
            clientes, fornecendo-lhes a informação técnica optimizada que melhor
            se adapta suas necessidades.<br /><br />
            Tendo em vista os bons resultados a nossa orientação empresarial,
            tem como base três itens:
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <News />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style lang="scss">
#empresa {
  padding: 20px;
  background-color: white;
}
#main {
  strong {
    font-weight: bolder;
    color: #555;
  }

  p {
    color: #444;
    font-size: 1.1rem;
    text-align: justify;
  }
}
</style>

<script>
import News from "./News";

export default {
  name: "Main",
  components: { News }
};
</script>
