<template>
  <section id="team">
    <Banner image="/img/team.jpg" height="350">
      <v-row>
        <v-col cols="12">
          <h2 class="subtitle text-center">Nossa Equipa</h2>
          <p>
            Guerra & Ferreirinhas possui um corpo técnico altamente
            especializado, constituído por uma equipa multidisciplinar, com uma formação diversificada, nomeadamente licenciados
            nas áreas de contabilidade, gestão e economia.<br/> Dispomos de
            equipamento de última geração (software e hardware), e estamos
            dimensionados para atender rapidamente às múltiplas solicitações dos
            nossos clientes. A cada cliente é assim fornecida a solução
            optimizada que melhor se adapta aos objectivos em questão.
          </p>
        </v-col>
      </v-row>
    </Banner>
  </section>
</template>

<style lang="scss">
#team {
  background-color: darken(#026537, 10%);
  padding: 0;

  .subtitle {
    color: white;
  }

  p {
    color: white;
  }
}
</style>

<script>
import Banner from "../Banner";

export default {
  name: "Team",
  components: { Banner },
};
</script>
