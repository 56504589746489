<template>
  <v-col cols="12" md="" class="valor-item">
    <v-card flat>
      <v-card-text>
        <v-avatar size="78">
          <v-icon dark size="64" :color="color"> {{ icon }} </v-icon>
        </v-avatar>
        <p>
          {{ text }}
        </p>
      </v-card-text></v-card
    >
  </v-col>
</template>

<style lang="scss">
.principios {
  .v-card {
    padding: 10px;
    height: 100%;

    p {
      font-size: 0.9rem;
    }
  }

  .valores .valor-item .v-avatar {
    background-color: transparent;

    i {
      color: #999;
    }
  }
}
</style>

<script>
export default {
  name: "ValueItem",
  props: ["icon", "text", "color"],
};
</script>
