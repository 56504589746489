<template>
  <div id="customer">
    <Menu />
    <v-container>
      <v-row>
        <v-col cols="12" md="9">
          <h3 class="text-left">Utilizadores</h3>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            rounded
            single-line
            dense
            append-icon="mdi-magnify"
            type="text"
            name="input-10-1"
            label="buscar..."
            v-model="text"
            v-on:keyup.enter="load()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed color="primary" v-bind="attrs" v-on="on">
            Novo utilizador
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Novo utilizador</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nome"
                    v-model="editingUser.name"
                    :rules="[validator.empty]"
                    ref="name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="E-mail"
                    :rules="[validator.empty, validator.email]"
                    ref="email"
                    v-model="editingUser.email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Telefone"
                    ref="phone"
                    :rules="[validator.phone]"
                    v-model="editingUser.phone"
                    counter="9"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="passwordDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Deseja redefinir a palavra chave deste utilizador?
          </v-card-title>

          <v-card-text>
            Uma vez modificada o utilizador precisará da nova para conseguir
            aceder ao sistema.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="passwordDialog = false">
              Cancelar
            </v-btn>

            <v-btn color="red darken-1" text @click="resetUserPassword()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Deseja remover este utilizador?
          </v-card-title>

          <v-card-text>
            Todos os dados relativos a este utilizador será removido da base de
            dados.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="deleteDialog = false">
              Cancelar
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteUser()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="users"
            item-key="name"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" small>
                    <v-icon color="grey">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editItem(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="passwordRecovery(item)">
                    <v-list-item-icon>
                      <v-icon>mdi-lock-reset</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>Redefinir senha</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.avatar="{ item }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-avatar size="28" color="gray">
                  <img
                    :src="'/account/' + item.id + '/photo'"
                    alt="John"
                  /> </v-avatar
              ></v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
</style>
<script>
import Menu from "@/components/admin/Menu.vue";
import httpService from "../../services/httpService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";

export default {
  name: "Home",
  components: { Menu },
  methods: {
    editItem(item) {
      this.editingUser = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.editingUser = {
        id: item.id,
      };
    },
    passwordRecovery(item) {
      this.passwordDialog = true;
      this.editingUser = {
        id: item.id,
      };
    },
    load() {
      httpService.get("/api/users?size=1000&name=" + this.text).then((resp) => {
        this.users = resp.data;
      });
    },
    deleteUser() {
      httpService.delete("/api/users/" + this.editingUser.id).then(() => {
        this.load();
        this.editingUser = {};
        this.deleteDialog = false;
      });
    },
    resetUserPassword() {
      httpService
        .post("/account/" + this.editingUser.id + "/passwordReset")
        .then((res) => {
          this.editingUser = {};
          this.passwordDialog = false;
          this.notify.success(
            "A senha do utilizador foi redefinida. Nova senha: " + res.data
          );
        });
    },
    addUser() {
      return httpService.post("/api/users", this.editingUser);
    },
    updateUser() {
      return httpService.put(
        "/api/users/" + this.editingUser.id,
        this.editingUser
      );
    },
    save() {
      if (!this.validator.validate(this.$refs)) return;

      var promise = this.editingUser.id ? this.updateUser() : this.addUser();

      promise.then(() => {
        this.load();
        this.editingUser = {};
        this.dialog = false;
        this.validator.clear(this.$refs);
      });
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      notify: new NotificationService(this),
      dialog: false,
      deleteDialog: false,
      passwordDialog: false,
      users: [],
      validator: validationService,
      editingUser: {},
      text: "",
      headers: [
        { text: "", value: "actions", width: "50px" },
        { text: "", value: "avatar", width: "32px" },
        {
          text: "Nome",
          align: "start",
          value: "name",
        },
        { text: "E-mail", value: "email" },
        { text: "Telefone", value: "phone" },
      ],
    };
  },
};
</script>