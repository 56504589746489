<template>
  <div id="customer">
    <Menu />
    <v-container>
      <v-row>
        <v-col cols="12" md="9">
          <h3 class="text-left">Clientes / {{ customer.name }}</h3>
        </v-col>
        <v-col cols="12" md="3" v-if="false">
          <v-text-field
            outlined
            rounded
            single-line
            dense
            v-model="text"
            append-icon="mdi-magnify"
            type="text"
            name="input-10-1"
            label="buscar..."
            v-on:keyup.enter="load()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <CustomerFileForm :customerid="$route.params.id" :folders="folders" />
        </v-col>
        <v-col>
          <FolderForm :customerid="$route.params.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <CustomerFolderRender :customer="$route.params.id" :editing="true"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
</style>
<script>
import Menu from "@/components/admin/Menu.vue";
import FolderForm from "@/components/admin/FolderForm.vue";
import CustomerFileForm from "@/components/admin/CustomerFileForm.vue";
import CustomerFolderRender from "@/components/CustomerFolderRender.vue";
import httpService from "../../services/httpService";
import validationService from "../../services/validationService";

export default {
  name: "Customer",
  components: { Menu, CustomerFolderRender, FolderForm, CustomerFileForm },
  methods: {
    loadCustomer() {
      httpService
        .get("/api/customers/" + this.$route.params.id)
        .then((resp) => {
          this.customer = resp.data;
        });
    },
    loadFolders() {
      httpService
        .get("/api/customers/" + this.$route.params.id + "/folders")
        .then((resp) => {
          this.folders = resp.data;
        });
    },
  },
  created() {
    this.loadCustomer();
    this.loadFolders();
    this.$root.$on("folder-saved", () => this.loadFolders());
  },
  data() {
    return {
      dialog: false,
      validator: validationService,
      text: "",
      customer: {},
      folders: [],
    };
  },
};
</script>