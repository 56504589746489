<template>
  <footer>
    <cookielw theme="dark-lime" message="Utilizamos cookiers para garantir uma melhor experiência e desempenho do nosso site. Ao continuar a navegar estarás a aceitar o depósito de cookies." button-text="Aceitar"></cookielw>
  </footer>
</template>
 
<script>
  import cookielw from 'vue-cookie-law'
  export default {
    components: { cookielw }
  }
</script>