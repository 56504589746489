import httpService from '../../services/httpService'

// initial state
const state = () => ({
    forms: []
})

// getters
const getters = {}

// actions
const actions = {
    getForms({ commit }) {
        httpService.get("/forms").then(res => {
            commit('setForms', res.data)
        })
    }
}

// mutations
const mutations = {
    setForms(state, forms) {
        state.forms = forms;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}