<template>
  <div id="customer">
    <Menu />
    <v-container>
      <v-row>
        <v-col cols="12" md="9">
          <h3 class="text-left">Notícias</h3>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            rounded
            single-line
            dense
            append-icon="mdi-magnify"
            type="text"
            name="input-10-1"
            label="buscar..."
            v-model="title"
            v-on:keyup.enter="load()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="news"
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:item.date="{ item }">
              {{formatDate(item.date)}}
            </template>
            <template v-slot:item.link="{ item }">
              <a :href="item.link" target="_blank">{{item.link}}</a>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
</style>
<script>
import Menu from "@/components/admin/Menu.vue";
import httpService from "../../services/httpService";
import moment from 'moment';

export default {
  name: "Home",
  components: { Menu },
  methods: {
    load() {
      httpService.get("/api/news?size=1000&title=" + this.title).then((resp) => {
        this.news = resp.data;
      });
    },
    formatDate(val) {
      return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  created() {
    this.load();
  },
  data: () => ({
    news: [],
    title: "",
    headers: [
      {
        text: "Título",
        align: "start",
        value: "title",
      },
      { text: "Link", value: "link" },
      { text: "Fonte", value: "source" },
      { text: "Data", value: "date" },
    ],
  }),
};
</script>