<template>
  <v-dialog v-model="dialogFolder" max-width="400px" @click:outside="clearForm()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="primary" v-bind="attrs" v-on="on">
        Adicionar Pasta
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Nova pasta</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Nome"
                v-model="folderName"
                :rules="[validator.empty]"
                ref="folderName"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="saveFolder()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 
<script>
import httpService from "../../services/httpService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";

export default {
  name: "FolderForm",
  props: ["customerid"],
  methods: {
    clearForm() {
          this.validator.clear(this.$refs);
    },
    saveFolder() {
      if (!this.validator.validate(this.$refs)) return;
      httpService
        .post("/api/customers/" + this.customerid + "/folders", {
          name: this.folderName,
        })
        .then(() => {
          this.$root.$emit("folder-saved");
          this.dialogFolder = false;
          this.folderName = null;
          this.notify.success("Pasta adicionada com sucesso.");
          this.clearForm();
        });
    },
  },
  data() {
    return {
      validator: validationService,
      notify: new NotificationService(this),
      dialogFolder: false,
      folderName: "",
    };
  },
};
</script>