import httpService from "./httpService";

class AuthService {

    constructor() {
    }

    login(login, password, type) {
        return new Promise((resolve, reject) => {
            httpService.
                post("/account/token", { login: login, password: password })
                .then(resp => {
                    if (resp.data.type !== type) {
                        reject();
                        return;
                    }
                    window.sessionStorage.setItem("user", JSON.stringify(resp.data));
                    resolve(resp.data);
                }).catch(err => reject(err));
        });
    }

    logout() {
        return new Promise((resolve) => {
            window.sessionStorage.removeItem("user");
            resolve();
        });
    }

    getUser() {
        return new Promise((resolve, reject) => {
            var user = window.sessionStorage.getItem("user");

            if (user === null) {
                reject()
                return;
            }

            resolve(JSON.parse(user));
        });
    }
}

export default new AuthService();