<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-avatar size="28" color="grey" v-if="currentUser">
            <img :src="currentUser.photo" v-if="currentUser.photo" alt="John" />
            <span v-if="!currentUser.photo" class="white--text">{{
              currentUser.initials
            }}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card
        class="user-card"
        flat
        align="center"
        width="250px"
        v-if="currentUser"
      >
        <v-row align="center">
          <v-col cols="12" align="center">
            <v-avatar size="96" color="grey">
              <input
                id="user-photo-file"
                type="file"
                ref="file"
                v-on:change="selectFile"
              />
              <span v-if="!currentUser.photo" class="white--text headline">
                <v-btn
                  id="camera"
                  icon
                  color="white"
                  width="96px"
                  height="96px"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </span>
              <v-img
                v-if="currentUser.photo"
                :src="currentUser.photo"
                alt="John"
                class="text-center"
              >
                <v-btn icon color="white" width="96px" height="96px">
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label v-if="currentUser.name">{{ currentUser.name }}</label>
              <label v-if="currentUser.email">{{ currentUser.email }}</label>
              <label v-if="currentUser.nif">NIF: {{ currentUser.nif }}</label>
              <label v-if="currentUser.phone"
                >Contacto: {{ currentUser.phone }}</label
              >
              <v-btn
                text
                color="primary"
                small
                v-on:click="changePasswordDialog = true"
                >Alterar senha</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn text color="primary" small v-on:click="logout()"
                >Sair</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-dialog v-model="changePasswordDialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="headline">Alterar palavra passe</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Palavra passe atual"
                  v-model="changePassword.currentPassword"
                  :rules="[validator.empty]"
                  ref="currentPassword"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nova palavra passe"
                  v-model="changePassword.newPassword"
                  :rules="[validator.empty]"
                  ref="newPassword"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="changeUserPassword()">
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 <style lang="scss">
.user-card {
  min-width: 250px;

  #camera {
    position: absolute;
    left: 0;
    top: 0;
  }
  label {
    display: block;
    margin-bottom: 10px;
  }

  #user-photo-file {
    height: 96px;
    width: 96px;
    display: block;
    position: absolute;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
<script>
import { mapState } from "vuex";
import httpService from "../services/httpService";
import authService from "../services/authService";
import validationService from "../services/validationService";

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.loggedUser,
    }),
  },
  methods: {
    selectFile(args) {
      this.uploadPhoto(args.srcElement.files[0]);
    },
    loadInfo() {
      httpService.get("/account/info").then((resp) => {
        this.currentUser = resp.data;
      });
    },
    logout() {
      authService.logout().then(() => {
        this.$router.push("/");
      });
    },
    uploadPhoto(file) {
      if (!file) return;

      httpService
        .postFormData("/account/" + this.user.id + "/photo", { file: file })
        .then(() => {
          this.currentUser.photo =
            "/account/" + this.user.id + "/photo?rn=" + Math.random();
          this.$root.$emit("notify", {
            message: "Foto alterada com sucesso.",
          });
        });
    },
    changeUserPassword() {
      if (!this.validator.validate(this.$refs)) return;

      httpService
        .put("/account/" + this.user.id + "/password", this.changePassword)
        .then(() => {
          this.changePassword = {};
          this.changePasswordDialog = false;
          this.$root.$emit("notify", {
            message: "Palavra chave alterada com sucesso.",
          });
        });
    },
  },
  created() {
    this.$store.dispatch("auth/getUser").then(() => {
      this.loadInfo();
    });
  },
  data() {
    return {
      validator: validationService,
      changePassword: {},
      changePasswordDialog: false,
      currentUser: {},
      selectedPhoto: null,
    };
  },
};
</script>