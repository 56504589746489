<template>
  <v-dialog v-model="dialog" max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" text small v-bind="attrs" v-on="on">
        Esqueceu a palavra chave?
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <span class="headline">Recuperar palavra chave</span>
          </v-col></v-row
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-if="type == 'nif'"
                label="NIF"
                :rules="[validator.empty, validator.nif]"
                v-model="data.login"
                ref="nif"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-if="type == 'email'"
                label="E-mail"
                :rules="[validator.empty, validator.email]"
                v-model="data.login"
                ref="email"
                outlined
                dense
              ></v-text-field>
              <v-btn color="primary" @click="send()"> Recuperar </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
 
<script>
import httpService from "../../services/httpService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";

export default {
  name: "PasswordRecovery",
  props: ["type"],
  methods: {
    selectFile(file) {
      this.document.file = file;
    },
    send() {
      if (!this.validator.validate(this.$refs)) return;

      httpService
        .post("/account/recovery", this.data)
        .then(() => {
          this.dialog = false;
          this.data = {};
          this.notify.success(
            "Seu pedido de recuperação de palavra chave foi recebido, em breve a nossa equipa entrará em contacto."
          );
          this.validator.clear(this.$refs);
        })
        .catch(() => {
          this.notify.error(
            "Não foi possivel receber o pedido, por favor tente novamente."
          );
        });
    },
  },
  data() {
    return {
      validator: validationService,
      notify: new NotificationService(this),
      dialog: false,
      data: {},
    };
  },
};
</script>