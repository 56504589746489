class NotificationService {

    constructor(component) {
        this.root = component.$root;
    }

    subscribe(callback) {
        this.root.$on("notify", callback);
    }

    error(message) {
        this.root.$emit("notify", {
            message: message,
            error: true
        });
    }

    success(message) {
        this.root.$emit("notify", {
            message: message,
        });
    }
}

export default NotificationService;