import httpService from '../../services/httpService'

// initial state
const state = () => ({
    workspaces: []
})

// getters
const getters = {}

// actions
const actions = {
    getWorkspaces({ commit }) {
        return httpService.get("/workspaces").then(res => {
            commit('setWorkspaces', res.data)
        });
    }    
}

// mutations
const mutations = {
    setWorkspaces(state, workspaces) {
        state.workspaces = workspaces;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}