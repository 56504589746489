import httpService from '../../services/httpService'

// initial state
const state = () => ({
    components: [],
    currentForm: {
        fields: []
    },
    editingField: null
})

// getters
const getters = {}

// actions
const actions = {
    getFormComponents({ commit }) {
        httpService.get("/components").then(res => {
            commit('setFormComponents', res.data)
        })
    },
    saveForm({state}, workspace) {
        state.currentForm.workspaceId = workspace;
        return httpService.post("/forms", state.currentForm)
    }
}

// mutations
const mutations = {
    clear(state) {
        state.currentForm = {
            fields: []            
        };
        state.editingField = null;
    },
    setFormComponents(state, components) {
        state.components = components;
    },
    addField(state, field) {
        state.currentForm.fields.push(field);
    },
    updateFields(state, list) {
        var newField = list.filter(l => l._isNew === true);

        console.log(list);
        console.log(newField);

        if (newField.length > 0){
            newField[0]._isNew = false;
            state.editingField = newField[0];
        }

        state.currentForm.fields = list;
    },
    removeField(state, field) {
        var index = state.currentForm.fields.indexOf(field);

        if (index < 0)
            return;

        state.currentForm.fields.splice(index, 1);

        if (state.editingField === field)
            state.editingField = null;
    },
    editField(state, field) {
        state.editingField = field;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}