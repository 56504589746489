<template>
  <v-card flat color="grey lighten-4">
    <div class="file-menu" v-if="editing">
      <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            Deseja remover este ficheiro?
          </v-card-title>

          <v-card-text>
            Este ficheiro será removido da base de dados e não poderá ser
            recuperado.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="grey darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>

            <v-btn color="red darken-1" text @click="remove()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" small>
            <v-icon color="grey">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="dialog = true">
            <v-list-item-icon> <v-icon>mdi-delete</v-icon></v-list-item-icon>
            <v-list-item-title>Remover</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-row align="center">
      <v-col cols="12" align="center">
        <v-avatar size="42" color="blue-grey lighten-5">
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="12" align="center">
        <h5>{{ item.name }}</h5>
        <small>{{ formattedDate() }}</small>
        <v-btn color="purple lighten-2" text v-on:click="downloadFile()">
          DOWNLOAD
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss">
.file-menu {
  position: absolute;
  right: 4px;
  top: 4px;
}
</style>

<script>
import moment from "moment";
import httpService from "../../services/httpService";
import NotificationService from "../../services/notificationService";

export default {
  name: "File",
  props: ["item", "editing"],
  computed: {
    icon() {
      if (this.item.type === "PDF") return "mdi-file-pdf-box";
      if (this.item.type === "PNG") return "mdi-image-outline";
      return "mdi-file-chart";
    },
    color() {
      if (this.item.type === "PDF") return "red";
      if (this.item.type === "PNG") return "green";
      return "blue";
    },
  },
  methods: {
    downloadFile() {
      window.location.href = "/blobs/" + this.item.id;
    },
    formattedDate() {
      return moment(this.item.date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    remove() {
      httpService
        .delete(
          "/api/customers/" +
            this.item.customerId +
            "/documents/" +
            this.item.id
        )
        .then(() => {
          this.notify.success("Ficheiro removido com sucesso.");
          this.$root.$emit("customer-files-changed", {
            folder: this.item.folderId
          });
        });
    },
  },

  data() {
    return {
      notify: new NotificationService(this),
      dialog: false,
    };
  },
};
</script>
