import authService from "../../services/authService";

// initial state
const state = () => ({
    loggedUser: null
})

// getters
const getters = {}

// actions
const actions = {
    getUser({ commit }) {
        authService.getUser().then(user => {
            commit('setUser', user)
        });
    },
    logout() {
    },
    login() {
    }
}

// mutations
const mutations = {
    setUser(state, user) {
        state.loggedUser = user;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}