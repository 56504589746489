import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Customer from '../views/Customer.vue'
import AdminLogin from '../views/admin/Login.vue'
import AdminHome from '../views/admin/Home.vue'
import AdminUsers from '../views/admin/Users.vue'
import AdminNews from '../views/admin/News.vue'
import AdminCustomer from '../views/admin/Customer.vue'
import authService from '../services/authService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/customer',
    name: 'Customer',
    component: Customer,
    meta: {
      type: "customer"
    }
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: AdminLogin,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/admin/customers',
    name: 'AdminHome',
    component: AdminHome,
    meta: {
      type: "admin"
    }
  },
  {
    path: '/admin/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: {
      type: "admin"
    }
  },
  {
    path: '/admin/news',
    name: 'AdminNews',
    component: AdminNews,
    meta: {
      type: "admin"
    }
  },
  {
    path: '/admin/customers/:id',
    name: 'AdminCustomer',
    component: AdminCustomer,
    meta: {
      type: "admin"
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous)
    next();
  else {
    authService.getUser().then(user => {
      if (user.type !== to.meta.type) {
        if (to.meta.type === "admin")
          next({ path: "/admin" });
        else
          next({ path: "/" });
      }
      else
        next();
    }).catch(() => {
      if (to.meta.type === "admin")
        next({ path: "/admin" });
      else
        next({ path: "/" });
    });
  }
});

export default router
