<template>
  <section id="#principios" class="principios">
    <v-container class="text-center">
      <v-row>
        <v-col cols="12" class="principio-item missao">
          <h2 class="subtitle text-center">
            <v-icon dark size="48"> mdi-rocket </v-icon> Missão
          </h2>
          <p>
            Melhoria constante do serviço prestado ao cliente através de
            funcionários competentes e motivados, utilizando tecnologia de ponta
            e métodos inovadores ajudando os clientes a alcançar o sucesso.
          </p>
        </v-col>
        <v-col cols="12" class="principio-item visao">
          <h2 class="subtitle text-center">
            <v-icon dark size="48"> mdi-target </v-icon> Visão
          </h2>
          <p>
            A nossa visão é tornar-nos cada vez melhores e aprendermos cada vez
            mais, para podermos dar o melhor de nós a todos os nossos clientes,
            na senda de criarem negócios bem sucedidos.
          </p>
        </v-col>
        <v-col cols="12" class="principio-item valores">
          <h2 class="subtitle text-center">
            <v-icon dark size="44"> mdi-diamond-stone </v-icon> Valores
          </h2>
          <v-row>
            <ValueItem
            color="amber darken-2"
              icon="mdi-star"
              text="A qualidade do serviço ao cliente é a nossa principal
                    prioridade. Fomos avaliados pela Ordem dos Contabilistas
                    Certificados com uma classificação excelente."
            />
            <ValueItem
            color="green darken-2"
              icon="mdi-handshake"
              text="Contribuímos para o sucesso dos nossos clientes, através do
                    desenvolvimento de alianças de longa duração."
            />
            <ValueItem
            color="red darken-4"
              icon="mdi-account-group"
              text="Os nossos funcionários são a razão da nossa força. É a sua
                    inteligência e vitalidade que determinam a nossa reputação.
                    O envolvimento e trabalho em equipa são essenciais para o
                    nosso sucesso."
            />
            <ValueItem
              icon="mdi-check-all"
            color="blue darken-2"
              text="A nossa integridade nunca está comprometida. A nossa empresa
                    baseia-se numa gestão honrada e responsável."
            />
            <ValueItem
            color="teal darken-2"
              icon="mdi-cog-refresh"
              text="A melhoria constante é essencial ao nosso sucesso. A busca
                    da excelência é a nossa razão de ser."
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<style lang="scss">
.principios {
  background-color: #f2f2f2;

  .principio-item {
    padding: 40px;

    .subtitle i {
      margin-top: -5px;
    }
  }

  .v-avatar {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .subtitle i {
    color:inherit;
  }
}
</style>

<script>
import ValueItem from "./ValueItem";

export default {
  name: "Mission",
  components: { ValueItem },
};
</script>
