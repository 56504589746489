<template>
  <v-card id="customer-login" class="customer-login" flat>
    <v-card-text class="text-center">
      <h3 class="subtitle">Área do Cliente</h3>
      <v-text-field
        label="NIF"
        :rules="[validator.empty, validator.nif]"
        v-model="userName"
        ref="username"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        label="Palavra passe"
        v-model="password"
        :rules="[validator.empty]"
        ref="password"
        type="password"
        outlined
        dense
      ></v-text-field>
      <v-btn v-on:click="login()" color="primary" tile> Entrar </v-btn>
      <br />
      <PasswordRecovery type="nif" />
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
#customer-login {
  .v-card__text {
    padding: 30px !important;
  }

  h2 {
    margin-bottom: 20px;
  }

  text-align: center;

  .v-btn {
    display: inline-block;
    margin-bottom: 20px;
    text-transform: none;
  }
}
</style>

<script>
import authService from "../../services/authService";
import validationService from "../../services/validationService";
import NotificationService from "../../services/notificationService";

import PasswordRecovery from "./PasswordRecovery";

export default {
  name: "Header",
  components: { PasswordRecovery },
  methods: {
    login() {
      if (!this.validator.validate(this.$refs)) return;
      authService
        .login(this.userName, this.password, "customer")
        .then(() => {
          this.$router.push("/customer");
        })
        .catch((err) => {
          if (!err.response.data) {
            this.notify.error("Dados de acesso inválidos");
            return;
          }

          if (err.response.data.message)
            this.notify.error(err.response.data.message);
          else if (err.response.data.Message)
            this.notify.error(err.response.data.Message);
        });
    },
  },
  data() {
    return {
      validator: validationService,
      userName: "",
      password: "",
      notify: new NotificationService(this),
    };
  },
};
</script>
