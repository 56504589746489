<template>
  <div>
    <v-app-bar id="top-menu" app height="90px" dense flat elevate-on-scroll>
      <v-container>
        <a v-on:click="$router.push('/')">
          <v-img
            :src="require('../../assets/logo-sm.png')"
            v-if="!this.$vuetify.breakpoint.mobile"
            contain
          ></v-img>
          <v-img
            :src="require('../../assets/logo-md.png')"
            v-if="this.$vuetify.breakpoint.mobile"
            height="40px"
            width="40px"
            contain
          ></v-img>
        </a>
        <label> Administração</label>

        <v-spacer></v-spacer>
        <v-tabs
          v-if="!this.$vuetify.breakpoint.mobile"
          right
          slider-color="green"
          flat
          v-model="selectedTab"
        >
          <v-tab
            link
            :ripple="false"
            :to="tab.to"
            v-for="tab in tabs"
            :key="tab.id"
            >{{ tab.name }}</v-tab
          >
        </v-tabs>

        <UserCard />

        <v-btn
          icon
          v-bind="attrs"
          v-on:click="sheet = true"
          v-if="this.$vuetify.breakpoint.mobile"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-bottom-sheet v-model="sheet">
          <v-list>
            <v-list-item
              v-for="tile in tabs"
              :key="tile.name"
              @click="$router.push(tile.to)"
            >
              <v-list-item-title>{{ tile.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-bottom-sheet>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss">
#top-menu {
  z-index: 10;
}

#top-menu {
  a {
    margin-top: 6px;
    .v-image {
      display: inline-block;
      max-width: 120px;
      max-height: auto;
    }
  }

  label {
    display: inline-block;
    min-width: 200px;
    margin-top: 12px;
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  .v-tab {
    text-transform: none;
  }

  .v-tab--active {
    color: #026537;
  }

  .container {
    display: flex;
  }

  .v-tabs-slider.green {
    background-color: #9ccc62 !important;
    border-color: #9ccc62 !important;
  }
}

#top-menu.theme--light {
  background-color: white;
}
</style>

<script>
import { mapState } from "vuex";
import UserCard from "../UserCard";

export default {
  name: "Menu",
  components: { UserCard },
  methods: {
    showExtension(component) {
      this.currentExtension = component;
      this.extension = true;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.loggedUser,
    }),
  },
  created() {
    this.$store.dispatch("auth/getUser");
  },
  data() {
    return {
      sheet: false,
      selectedTab: 0,
      tabs: [
        { id: 1, name: "Clientes", to: "/admin/customers" },
        { id: 2, name: "Utilizadores", to: "/admin/users" },
        { id: 3, name: "Notícias", to: "/admin/news" },
      ],
    };
  },
};
</script>
